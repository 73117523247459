* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: #FBFBFB;
  overflow: auto;
  min-height: 100vh;
  color: #333;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

fieldset:disabled button {
  opacity: 0.5;
}

.alert {
  padding: 2rem;
  background-color: #1976D2;
  border-radius: 0.25rem;
  color: #fff;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 0.875rem;
}

.container {
  width: calc(100% - 4rem);
  margin: 2rem auto 2rem auto;
  min-width: calc(320px - 2rem);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content;
  grid-gap: 2rem;
}

.container:not(.flat) {
  margin-top: 4rem;
}

.container.flat {
  grid-template-columns: 1fr;
  margin-bottom: 6rem;
}

.header {
  width: 100%;
  min-width: 320px;
  background-color: #fff;
  border-bottom: 1px solid #EBEBEB;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 99999999;
}

.top-bar {
  border-bottom: 1px solid #EBEBEB;
  display: flex;
}

.top-bar-link {
  line-height: 3rem;
  font-size: 0.75rem;
  color: #666;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  text-align: center;
  flex-grow: 1;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
}

.top-bar-link:first-child {
  border-right: 1px solid #EBEBEB;
}

.top-bar-link .material-icons {
  line-height: 3rem;
  display: inline-block;
}

.navigation {
  display: flex;
  flex-direction: column;
}

.logo {
  margin-top: 1rem;
  padding: 1rem 0;
  text-align: center;
}

.logo img {
  height: 1.5rem;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
}

.menu-item {
  display: block;
  flex-grow: 1;
}

.menu-link {
  display: block;
  text-align: center;
  line-height: 3rem;
  text-decoration: none;
  color: #333;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 900;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;
}

.menu-link.active {
  border-bottom-color: #1976D2 !important;
}

.menu-link:hover {
  border-bottom-color: #1976D2A0;
}

.store {
  display: block;
  line-height: 3rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #1976D2;
  color: #FFFFFF;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  padding: 0 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.25rem 1rem 1px rgba(0, 0, 0, 0.55);
  font-size: 0.875rem;
  font-weight: 900;
  text-decoration: none;
  z-index: 99999;
}

.store .material-icons {
  float: right;
  line-height: 3rem;
  margin-left: 1rem;
}

.card {
  grid-column: span 4;
  width: 100%;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25rem;
}

.card.flat {
  text-align: left;
  margin-top: 2rem;
}

.card.flat:first-child {
  margin-top: 0;
}

.card:not(.flat) h2 {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  padding: 0;
  margin: 2rem 0 0 0;
  font-size: 1.2rem;
  font-weight: 900;
  color: #333;
}

.card .card-icon .material-icons {
  font-size: 5rem;
  color: #1976D2;
}

h1 {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 900;
  padding: 2rem 0 0 2rem;
  margin: 0;
  color: #333;
}

h2 {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 2rem 0 0 0;
  margin: 0;
  color: #333;
}

h2:first-child {
  padding-top: 0;
}

h3 {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 900;
  padding: 2rem 0 0 0;
  margin: 0;
  color: #333;
}

h4 {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 900;
  padding: 2rem 0 0 0;
  margin: 0;
  color: #333;
}

p {
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 0.875rem;
  padding: 2rem 0 0 0;
  margin: 0;
  line-height: 1.5rem;
  color: #666;
}

ul {
  margin: 0;
  padding: 2rem 0 0 1rem;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #666;
}

.field {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  border: 1px solid #EBEBEB;
  font-size: 0.875rem;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
}

textarea.field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  height: auto;
}

.field:focus {
  outline: none;
  box-shadow: 0 0 0 3px #1976D280;
  border-color: #1976D2;
}

.button {
  position: relative;
  display: inline-block;
  line-height: 3rem;
  background-color: #1976D2;
  color: #FFFFFF;
  font-family: Lato, Roboto, Helvetica, sans-serif;
  padding: 0 1.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 900;
  text-decoration: none;
  border: none;
}

.contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  font-size: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
}

.contact:last-child {
  padding-bottom: 0;
}

.contact span {
  display: block;
  font-family: Lato, Roboto, Helvetica, sans-serif;
}

.contact span:first-child {
  font-weight: 900;
  text-align: left;
  margin-right: 2rem;
  margin-left: 0;
  margin-bottom: 0.5rem;
}

.top-offset {
  padding-top: 184px;
}

@media (min-width: 600px) {
  .container {
    width: calc(600px - 4rem);
  }

  .card {
    grid-column: span 2;
  }
}

@media (min-width: 960px) {
  .container {
    width: 720px;
  }

  .top-offset {
    padding-top: 122px;
  }

  .contact {
    flex-direction: row;
  }

  .top-bar-link {
    border-right: 1px solid #EBEBEB;
    flex-grow: 0;
    padding: 0 2rem;
  }

  .top-bar-link .material-icons {
    margin-right: 1rem  ;
  }

  .navigation {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
  }

  .logo {
    padding: 1.5rem 0;
    margin: 0;
  }

  .logo img {
    display: block;
    height: 1.5rem;
    padding: 0;
    margin: 0;
  }

  .menu {
    width: auto;
  }

  .menu-item {
    width: auto;
  }

  .menu-link {
    margin: 0 0.5rem;
    padding: 0 1rem;
    line-height: calc(4.5rem - 10px);
  }

  .store {
    position: static;
    padding: 0 1rem;
    border-radius: 0.25rem;
    height: 3rem;
    align-self: center;
    box-shadow: none;
    margin: 0 0 0 2rem;
  }
}
